import React, { Fragment, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import "./ProductReviews.css";
import { useSelector, useDispatch } from 'react-redux';
import { clearErrors, getAllReviews, deleteReviews } from '../../actions/productAction';
import { Button } from "@mui/material";
import MetaData from "../layout/MetaData";
import { Star, Delete } from "@mui/icons-material";
import SideBar from "./Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { DELETE_REVIEW_RESET } from '../../constants/productConstants';


const ProductReviews = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { error, reviews, loading } = useSelector((state) => state.productReviews);

    const { error: deleteError, isDeleted } = useSelector((state) => state.deleteReview);

    const [productId, setProductId] = useState("")

    const getValues = (reviews, key) => {
        return reviews[key];
    }

    const deleteReviewHandler = (reviewId) => {
        dispatch(deleteReviews(reviewId, productId));
    };

    const productReviewsSubmitHandler = (e) => {
        e.preventDefault();
        dispatch(getAllReviews(productId));
    };

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearErrors());
        }

        if (deleteError) {
            toast.error(deleteError);
            dispatch(clearErrors());
        }

        if (isDeleted) {
            toast.success("Review Deleted Success");
            navigate("/admin/reviews");
            dispatch({ type: DELETE_REVIEW_RESET });
        }
    }, [dispatch, error, navigate, deleteError, isDeleted]);

    const columns = [
        {
            field: "id",
            headerName: "Review ID",
            minWidth: 300,
            flrx: 0.5
        },
        {
            field: "user",
            headerName: "User",
            minWidth: 180,
            flex: 0.6,
        },
        {
            field: "comment",
            headerName: "Comment",
            minWidth: 350,
            flex: 0.1,
        },
        {
            field: "rating",
            headerName: "Rating",
            type: "number",
            minWidth: 180,
            flex: 0.4,
            cellClassName: (id) => {
                return getValues(id, "rating") >= 3 ? "greenColor" : "redcolor";
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "number",
            minWidth: 150,
            flex: 0.3,
            sortable: false,
            renderCell: (id) => {
                // const id = params.get('id');
                return (
                    <Fragment>
                        <Button onClick={() => deleteReviewHandler(getValues(id, "id"))}>
                            <Delete />
                        </Button>
                    </Fragment>
                );
            },
        },
    ];

    const rows = [];

    reviews &&
        reviews.forEach((item) => {
            rows.push({
                id: item._id,
                rating: item.rating,
                comment: item.comment,
                user: item.name,
            });
        });

    return (
        <Fragment>
            <MetaData title={`All REVIEWS - ADMIN`} />
            <div className='dashboard'>
                <SideBar />
                <div className='productReviewsContainer'>
                    <form
                        className='productReviewsForm '
                        encType='multipart/form-data'
                        onSubmit={productReviewsSubmitHandler}
                    >
                        <h1 className='productReviewsFormHeading '>ALL REVIEWS</h1>
                        <div>
                            <Star />
                            <input
                                type='text'
                                placeholder='Product Id'
                                required
                                value={productId}
                                onChange={(e) => setProductId(e.target.value)}
                            />
                        </div>
                        <Button
                            id="createProductBtn"
                            type="submit"
                            disabled={loading ? true : false || productId === " " ? true : false}
                        >
                            Search
                        </Button>
                    </form>

                    {reviews && reviews.length > 0 ? <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableRowSelectionOnClick
                        className='productListTable'
                        autoHeight
                    /> : <h1 className='productReviewsFormHeading'>No Reviews Found</h1>}
                </div>
            </div>
            <ToastContainer />
        </Fragment>
    )
}


export default ProductReviews