import React, { Fragment, useEffect } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import "./MyOrders.css";
import { useSelector, useDispatch } from 'react-redux';
import { clearErrors, myOrders } from '../../actions/orderAction';
import Loader from '../layout/Loader/loader';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typography } from "@mui/material";
import MetaData from '../layout/MetaData';
import { Launch } from '@mui/icons-material';
// import { useParams } from 'react-router-dom';
// import { getValueFromValueOptions } from '@mui/x-data-grid/components/panel/filterPanel/filterPanelUtils';


const MyOrders = () => {
    const dispatch = useDispatch();
    // const { id } = useParams();
    

    const { loading, error, orders } = useSelector((state) => state.myOrders);
    const { user } = useSelector((state) => state.user);

    const getValues = (orders, key) => {
        return orders[key];
    };

    const columns = [
        {
            field: "id",
            headerName: "Order ID",
            minWidth: 300,
            flex: 1,
        },
        
        {
            field: "createdAt",
            headerName: "Order Date",
            minWidth: 150,
            flex: 0.5,
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 150,
            flex: 0.5,
            cellClassName : (id) => {
            return getValues(id , "status") === "Delivered" ? "greenColor" : "redcolor";
            },
        },
        {
            field: "itemsQty",
            headerName: "Items Qty",
            type : "number",
            minWidth: 150,
            flex: 0.5,
        },
        {
            field: "amount",
            headerName: "Amount",
            type : "number",
            minWidth: 200,
            flex: 0.5,
        },
        {
            field : "actions",
            flex : 0.3,
            headerName : "Actions",
            minWidth : 150,
            type : "number",
            sortable : false,
            renderCell : (id) => {
                return (
                    <Link to={`/order/${getValues(id , "id")}`}>
                        <Launch />
                    </Link>
                );
            },
        },
    ];
    const rows = [];
    orders && 
        orders.forEach((item , index) => {
            rows.push({
                itemsQty : item.orderItems.length,
                id : item._id,
                status : item.orderStatus,
                amount : item.totalPrice,
                createdAt : item.createdAt,
            });            
        });

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearErrors());
        }
        dispatch(myOrders());
    }, [dispatch, error]);

    return (
        <Fragment>
            <MetaData title={`${user.name} - Orders`} />

            {loading ? (
                <Loader />) : (
                <div className='myOrdersPage'>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableRowSelectionOnClick
                        className='myOrdersTable'
                        autoHeight
                    />

                    <Typography id='myOrdersHeading'>{user.name}'s Orders</Typography>
                </div>
            )}
            <ToastContainer />
        </Fragment>
    )
}

export default MyOrders