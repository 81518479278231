import React, { Fragment, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import "./ProductList.css";
import { useSelector, useDispatch } from 'react-redux';
import { clearErrors, getAdminProducts, deleteProduct } from '../../actions/productAction';
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import MetaData from "../layout/MetaData";
import { Edit, Delete } from "@mui/icons-material";
import SideBar from "./Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Loader from "../layout/Loader/loader.js"
import { DELETE_PRODUCT_RESET } from '../../constants/productConstants';


const ProductList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { error, products, loading } = useSelector((state) => state.products);

    const { error: deleteError , isDeleted} = useSelector((state) => state.deleteAndUpdateProduct);
    const getValues = (products, key) => {
        return products[key];
    }

    const deleteProductHandler = (id) => {
        dispatch(deleteProduct(id));
    };

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearErrors());
        }

        if(deleteError) {
            toast.error(deleteError);
            dispatch(clearErrors());
        }

        if(isDeleted) {
            toast.success("Product Deleted Success");
            navigate("/admin/dashboard");
            dispatch({type : DELETE_PRODUCT_RESET});
        }

        dispatch(getAdminProducts());
    }, [dispatch, error,  navigate, deleteError, isDeleted]);

    const columns = [
        {
            field: "id",
            headerName: "Product ID",
            minWidth: 300,
            flex: 0.5
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 350,
            flex: 0.1,
        },
        {
            field: "stock",
            headerName: "Stock",
            type: "number",
            minWidth: 150,
            flex: 0.3,
        },
        {
            field: "price",
            headerName: "Price",
            type: "number",
            minWidth: 200,
            flex: 0.5,
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "number",
            minWidth: 150,
            flex: 0.3,
            sortable: false,
            renderCell: (id) => {
                

                return (
                    <Fragment>
                        <Link to={`/admin/product/${getValues(id, "id")}`}>
                            <Edit />
                        </Link>
                        <Button onClick={() => deleteProductHandler(getValues(id, "id"))}>
                        <Delete />
                        </Button>
                    </Fragment>
                );
            },
        },
    ];

    const rows = [];

    products &&
        products.forEach((item) => {
            rows.push({
                id: item._id,
                stock: item.Stock,
                price: item.price,
                name: item.name,
                slug: item.slug,
            });
        });

    return (
        <Fragment>
            {loading ? <Loader /> : (
                <Fragment>
                    <MetaData title={`All PRODUCTS - ADMIN`} />
                    <div className='dashboard'>
                        <SideBar />
                        <div className='productListContainer'>
                            <h1 id="productListHeading">ALL PRODUCTS</h1>

                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={10}
                                disableRowSelectionOnClick
                                className='productListTable'
                                autoHeight
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </Fragment>
            )}
        </Fragment>

    )
}

export default ProductList;