import React from "react";
import { Link } from "react-router-dom";
import { Rating } from "@mui/lab";

const ProductCard = ({ product }) => {
  const options = {
    value: product.ratings,
    readOnly : true,
    precision : 0.5,
};

  return (
    <Link className="productCard" to={`/products/${product.slug}`}>
      <img src={product.images[0].url} alt={product.name} />
      <p>{product.name}</p>
      <div>
        <Rating {...options} /> <span className="productCardSpan"> ({product.numofReviews} Reviews) </span>
      </div>
      <span>{`₹${product.price}`}</span>
    </Link>
  );
};
export default ProductCard;
