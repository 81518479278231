import React, { Fragment, useEffect } from 'react';
import "./Cart.css";
import CartItemCard from "./CartItemCard.js";
import { useSelector, useDispatch } from "react-redux";
import { addItemToCart, removeItemsFromCart } from '../../actions/cartAction.js';
import { useNavigate } from 'react-router-dom';
import { RemoveShoppingCart } from '@mui/icons-material';
import { Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import MetaData from '../layout/MetaData.js';



const ADD_TO_CART = 'ADD_TO_CART';

const Cart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cartItems } = useSelector((state) => state.cart);
    // const { user } = useSelector(state => state.user);


    useEffect(() => {
        const storedCartItems = JSON.parse(localStorage.getItem('cartItems'));
        if (storedCartItems) {
            storedCartItems.forEach(storedCartItem => {

                dispatch({ type: ADD_TO_CART, payload: storedCartItem });
            });
        }
      },[]);


    const icreaseQuantity = (id, quantity, stock) => {
        const newQty = quantity + 1;
        if (stock <= quantity) {
            return;
        }
        dispatch(addItemToCart(id, newQty));
    };

    const decreaseQuantity = (id, quantity) => {
        const newQty = quantity - 1;
        if (1 >= quantity) {
            return;
        }
        dispatch(addItemToCart(id, newQty));
    };

    const deleteCartItems = (id) => {
        dispatch(removeItemsFromCart(id));
    };

    const checkoutHandler = () => {
        navigate("/shipping");
        // navigate("/login?redirect=shipping");
    }

    return (
        <Fragment>
            <MetaData title="Cart" />
            {/* || !cartItems.some(item => item.user_id === user._id) */}
            {cartItems.length === 0 ? (

                <div className='emptyCart'>
                    <RemoveShoppingCart />

                    <Typography>No Product in Your Cart</Typography>
                    <Link to="/products">View Product</Link>
                </div>

            ) : (
                <Fragment>
                    <div className='cartPage'>
                        <div className='cartHeader'>
                            <p>Product</p>
                            <p>Price</p>
                            <p>Quantity</p>
                            <p>Subtotal</p>
                        </div>

                        {cartItems && cartItems.map((item) => (

                            <div className='cartContainer' key={item.product}>
                                <CartItemCard item={item} deleteCartItems={deleteCartItems} />
                                <p className='cartPrice'>{`₹${item.price}`}</p>
                                <div className='cartInput'>
                                    <button onClick={() => decreaseQuantity(item.product, item.quantity)}>-</button>
                                    <input type='number' value={item.quantity} readOnly />
                                    <button onClick={() => icreaseQuantity(item.product, item.quantity, item.stock)}>+</button>
                                </div>
                                <p className='cartSubtotal'>{`₹${item.price * item.quantity}`}</p>
                            </div>


                        )
                        )}

                        <div className='cartGrossProfit'>
                            <div></div>
                            <div className='cartGrossProfitBox'>
                                <p>Gross Total</p>
                                <p>{`₹${cartItems.reduce(
                                    (acc, item) => acc + item.quantity * item.price,
                                    0
                                )}`}</p>
                            </div>
                            <div></div>
                            <div className='checkOutBtn'>
                                <button onClick={checkoutHandler}>Check Out</button>
                            </div>
                        </div>
                    </div>
                </Fragment>

            )}
        </Fragment>
    )
}

export default Cart

