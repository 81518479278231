import React from 'react';
import "./Sidebar.css";
import logo from "../../images/dm_logo.png";
import { Link } from 'react-router-dom';
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view"
// import { TreeItem } from "@mui/x-tree-view";
// import { TreeItem, TreeVie } from '@mui/lab';
import {  PostAdd, Add, ListAlt, Dashboard, People, RateReview } from '@mui/icons-material';


const Sidebar = () => {
    return (
        <div className='sidebar'>
            <Link to="/">
                <img src={logo} alt='Ecommerce' />
            </Link>
            <Link to="/admin/dashboard">
                <p>
                    <Dashboard />Dashboard
                </p>
            </Link>
            <Link>
                <SimpleTreeView
                    // defaultCollapseIcon={<ExpandMore />}
                    // defaultExpandIcon={<ImportExport />}
                >
                    <TreeItem itemId="1" label="Products" >
                        <Link to="/admin/products">
                            <TreeItem itemId="2" label="All" icon={<PostAdd />} />
                        </Link>
                        <Link to="/admin/product">
                            <TreeItem itemId="3" label="Create" icon={<Add />} />
                        </Link>
                    </TreeItem>
                </SimpleTreeView>
            </Link>
            <Link to="/admin/orders">
                <p>
                    <ListAlt /> Orders
                </p>
            </Link>
            <Link to="/admin/users">
                <p>
                    <People /> Users
                </p>
            </Link>
            <Link to="/admin/reviews">
                <p>
                    <RateReview /> Reviews
                </p>
            </Link>
        </div>
    )
};

export default Sidebar