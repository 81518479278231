import React, { Fragment, useState } from 'react'
import "./Header.css";
import { SpeedDialAction } from '@mui/lab';
import { SpeedDial } from '@mui/lab';
import { Dashboard, Person, ExitToApp, ListAlt , ShoppingCart} from "@mui/icons-material";
import { Backdrop } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logout } from "../../../actions/userAction";
import { useDispatch , useSelector } from "react-redux";


const UserOptions = ({ user }) => {
    const {cartItems} = useSelector((state) => state.cart);

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const options = [
        { icon: <ListAlt />, name: "Orders", func: orders },
        { icon: <Person />, name: "Profile", func: account },
        { icon: <ShoppingCart style={{color : cartItems.length > 0 ? "#5399DC" : "unset"}}/>, name: `Cart(${cartItems.length})`, func: cart },
        { icon: <ExitToApp />, name: "Logout", func: logoutUser },
    ];

    if (user.role === "admin") {
        options.unshift({ icon: <Dashboard />, name: "Dashboard", func: dashboard, })
    }

    function dashboard() {
        navigate("/admin/dashboard");
    }

    function orders() {
        navigate("/orders");
    }

    function account() {
        navigate("/account");
    }

    function cart() {
        navigate("/cart");
    }

    function logoutUser() {
        dispatch(logout());
        toast.success("Logout successfully");
    }

    return (
        <Fragment>
            <Backdrop open={open} style={{zIndex : 10}} />
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                direction="down"
                className="speedDial"
                icon={
                    <img
                        className="speedDialIcon"
                        src={user.avatar.url ? user.avatar.url : "/profile.png"}
                        alt="Profilr"
                    />
                }
            >
                {options.map((item) => (
                    <SpeedDialAction key={item.name}  icon={item.icon} tooltipTitle={item.name} onClick={item.func} tooltipOpen />
                ))}
            </SpeedDial>
            <ToastContainer position="top-center" />
        </Fragment>

    )
};

export default UserOptions