import { combineReducers, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import { deleteAndUpdateProductReducer, deleteReviewReducer, newProductReducer, newReviewReducer, productDetailsReducer, productsReducer, productReviewsReducer } from "./reducers/productReducers";
import { allUsersReducer, forgotPasswordReducer, profileReducer, userDetailsReducer, userReducer } from "./reducers/userReducers";
import { cartReducer } from "./reducers/cartReducer";
import { allOrdersReducer, myOrdersReducer, newOrderReducer, orderDetailsReducer, orderReducer } from "./reducers/orderReducers";

const reducer = combineReducers({
  products: productsReducer,
  productDetails: productDetailsReducer,
  user: userReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  cart: cartReducer,
  newOrder : newOrderReducer,
  myOrders : myOrdersReducer,
  orderDetails : orderDetailsReducer,
  newReview : newReviewReducer,
  newProduct : newProductReducer,
  deleteAndUpdateProduct : deleteAndUpdateProductReducer,
  allOrders : allOrdersReducer,
  order : orderReducer,
  allUsers : allUsersReducer,
  userDetails : userDetailsReducer,
  productReviews : productReviewsReducer,
  deleteReview : deleteReviewReducer,



});

let initialState = {
  cart: {
    cartItems: localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")) : [],
    shippingInfo: localStorage.getItem("shippingInfo") ? JSON.parse(localStorage.getItem("shippingInfo")) : {},
  },
};

const middleware = [thunk];

const store = configureStore(
  { reducer: reducer },
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
