import React, { Fragment, useEffect } from "react";
import { CgMouse } from "react-icons/cg";
import "./Home.css";
import Slider from "./Slider.js"
import ProductCard from "./ProductCard.js";
import MetaData from "../layout/MetaData.js";
import { getProduct } from "../../actions/productAction.js";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/loader.js";
import { LocalShipping, Shop, Support } from "@mui/icons-material"
import sideImage from "../../images/NX.jpeg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {

  desktop: {
    breakpoint: { max: 3000, min: 600 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1
  }
};


const Home = () => {

  const dispatch = useDispatch();
  const { loading, products } = useSelector(
    (state) => state.products
  );

  useEffect(() => {

    dispatch(getProduct());
  }, [dispatch]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="DM SECURIE" />

          <div className="banner">
            <p>Welcome to DM Securie</p>
            <h1>FIND AMAZING PRODUCT BELOW</h1>

            <a href="/products">
              <button>
                Explore
              </button>
            </a>

            <a href="#container">
              <button>
                Scroll <CgMouse />
              </button>
            </a>
          </div>

          <h2 className="homeHeading">Featured Products</h2>
          <div className="container" id="container">
            {/* <div className="exploreCard">
              <h3>Crafted with excellent material.</h3>
              <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique.</p>
              <a href="/products">
                <button>
                  Explore
                </button>
              </a>
            </div>
            
            {products &&
              products.map((product) => <ProductCard key={product._id} product={product} />)} */}
            <Carousel responsive={responsive}>
              <div className="exploreCard">
                <h3>Crafted with excellent material.</h3>
                <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique.</p>
                <a href="/products">
                  <button>
                    Explore
                  </button>
                </a>
              </div>
              {products &&
                products.map((product) => <ProductCard key={product._id} product={product} />)}
            </Carousel>
          </div>


          <div className="Why-Choose-Us-section">
            <div className="Why-Choose-Us">
              <h2>Why Choose Us</h2>
              <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique.</p>
              <div className="shipping">
                <LocalShipping />
                <h4>Fast & Free Shipping</h4>
                <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
              </div>
              <div className="shop">
                <Shop />
                <h4>Easy to Shop</h4>
                <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
              </div>
              <div className="support">
                <Support />
                <h4>24/7 Support</h4>
                <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
              </div>
            </div>
            <div className="sideImage">
              <img src={sideImage}></img>
            </div>
          </div>
          <div className="Why-Choose-Us-section">

            <div className="sideImage">
              <img src={sideImage}></img>
            </div>

            <div className="Why-Choose-Us">
              <h2>We Help You Make Modern Design</h2>
              <p>Donec facilisis quam ut purus rutrum lobortis. Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique. Pellentesque habitant morbi tristique senectus et netus et malesuada</p>
              <div className="section-2">
                <ul type="disc">
                  <li>Donec vitae odio quis nisl dapibus malesuada</li>
                  <li>Donec vitae odio quis nisl dapibus malesuada</li>
                  <li>Donec vitae odio quis nisl dapibus malesuada</li>
                  <li>Donec vitae odio quis nisl dapibus malesuada</li>
                </ul>
              </div>
            </div>
          </div>
          <Slider />



        </Fragment>
      )}
    </Fragment>
  );
};

export default Home;
