import React, { Fragment, useEffect, useRef } from 'react';
import CheckoutSteps from '../Cart/CheckoutSteps';
import { useSelector, useDispatch } from "react-redux";
import MetaData from '../layout/MetaData';
import { Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import "./Payment.css";
import { CreditCard, Event, VpnKey } from '@mui/icons-material';
import {  createOrder , clearErrors } from '../../actions/orderAction';

const Payment = () => {
  const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const payBtn = useRef(null);

  const { shippingInfo , cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.newOrder);

  const paymentData = {
    amount : Math.round(orderInfo.totalPrice * 100),
  };

  const order = {
    shippingInfo,
    orderItems : cartItems,
    itemsPrice : orderInfo.subtotal,
    taxPrice : orderInfo.tax,
    shippingPrice : orderInfo.shippingCharges,
    totalPrice : orderInfo.totalPrice,
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    payBtn.current.disabled = true;

    try {
      const config = {
        headers : {
          "Content-Type" : "application/json",
        },
      };
      const { data } = await axios.post(
        "/api/v1/payment/process",
        paymentData,
        config
      );
      const client_secret = data.client_secret;
      
      if (!stripe || !elements) return;

      const result = await stripe.confirmCardPayment(client_secret , {
        payment_method : {
          card : elements.getElement(CardNumberElement),
          billing_details : {
            name : user.name,
            email : user.email,
            address : {
              line1 : shippingInfo.address,
              city : shippingInfo.city,
              state : shippingInfo.state,
              postal_code : shippingInfo.pinCode,
              country : shippingInfo.country,
            },
          },
        },
      });

      if (result.error) {
        payBtn.current.disabled = false;

        if(result.error.message){
          toast.error(result.error.message);
        } else {
          toast.error("Oops! Something went wrong.");
        }
      } else {
        if (result.paymentIntent.status === "succeeded") {
          
          order.paymentInfo = {
            id : result.paymentIntent.id,
            status : result.paymentIntent.status,
          };
          dispatch(createOrder(order));

          navigate("/success");          
        } else {
          toast.error("There's some issue while processing payment");
        }
      }

    } catch (error) {
      payBtn.current.disabled = false;
      if(error.response.data.message){
        toast.error(error.response.data.message);
      } else {
        toast.error("Oops! something went wrong");
      }
    }
  };

  useEffect(() => {
    if(error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  } , [dispatch , error]);

  return (
    <Fragment>
      <MetaData title="Payment" />
      <CheckoutSteps activeStep={2} />
      <div className='paymentContainer'>
        <form className='paymentForm' onClick={(e) => submitHandler(e)}>
          <Typography>Card Info</Typography>
          <div>
            <CreditCard />
            <CardNumberElement className='paymentInput' />
          </div>
          <div>
            <Event />
            <CardExpiryElement className='paymentInput' />
          </div>
          <div>
            <VpnKey />
            <CardCvcElement className='paymentInput' />
          </div>

          <input
            type='submit'
            value={`Pay - ₹${orderInfo && orderInfo.totalPrice}`}
            ref={payBtn}
            className='paymentFormBtn'
          />
        </form>

      </div>
      <ToastContainer position="top-center"/>
    </Fragment>
  )
}

export default Payment