import React, { Fragment, useEffect, useState } from 'react';
// import "./NewProduct.css";
import "./UodateProduct.css";
import { useSelector, useDispatch } from 'react-redux';
import { clearErrors, updateProduct, getProductDetails } from '../../actions/productAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from "@mui/material";
import MetaData from '../layout/MetaData';
import { AccountTree, Description, Storage, Spellcheck, AttachMoney, Dns, AspectRatio, Lock, ColorLens, LineWeight, Equalizer, Apps, Verified } from '@mui/icons-material';
import SideBar from './Sidebar';
import { UPDATE_PRODUCT_RESET } from '../../constants/productConstants';

const UpdateProduct = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { error, product } = useSelector((state) => state.productDetails)

    const { error: updateError, loading, isUpdated } = useSelector((state) => state.deleteAndUpdateProduct);

    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");
    const [Stock, setStock] = useState(0);
    const [brand, setBrand] = useState("");
    const [dimensions, setDimensions] = useState("");
    const [lockType, setLockType] = useState("");
    const [color, setColor] = useState("");
    const [volume, setVolume] = useState("");
    const [weight, setWeight] = useState("");
    const [material, setMaterial] = useState("");
    const [warranty, setWarranty] = useState("");
    const [images, setImages] = useState([]);
    // const [oldImages, setOldImages] = useState([]);
    const [imagesPreview, setImagesPreview] = useState([]);


    const categories = [
        "Home Locker",
        "100X Stronger",
        "250X Stronger",
        "Fire Resistant Lockers",
        "Digital Lockers",
        "Mechanical Lockers",
        "Fingerprint Lockers",
    ];

    const productId = id;

    useEffect(() => {
        if (product && product._id !== productId) {
            dispatch(getProductDetails(productId));

        } else 
            (async function () {
                setName(product.name);
                setDescription(product.description);
                setPrice(product.price);
                setCategory(product.category);
                setStock(product.Stock);
                setBrand(product.brand);
                setDimensions(product.dimensions);
                setLockType(product.lockType);
                setColor(product.color);
                setVolume(product.volume);
                setWeight(product.weight);
                setMaterial(product.material);
                setWarranty(product.warranty);
                var Images = [];
                await Promise.all(product.images?.map((imageObj) => {
                    return new Promise((Resolve) => {
                        var xhr = new XMLHttpRequest();
                        xhr.onload = function () {
                            var reader = new FileReader();
                            reader.onloadend = function () {
                                Images.push(reader.result);
                                Resolve(true);
                            }
                            reader.readAsDataURL(xhr.response);
                        };
                        xhr.open('GET', imageObj.url);
                        xhr.responseType = 'blob';
                        xhr.send();
                    }).catch(() => { });
                })).then((values) => {
                    setImages(Images);
                    setImagesPreview(Images);
                });
            }());
            //  setImages(product.images);
            // setImagesPreview(product.images);
        

        if (error) {
            toast.error(error);
            // dispatch(clearErrors());
        }

        if (updateError) {
            toast.error(updateError);
            // dispatch(clearErrors());
        }

        if (isUpdated) {
            toast.success("Product Updated Successfully");
            dispatch(getProductDetails(productId));
            navigate("/admin/products");
            dispatch({ type: UPDATE_PRODUCT_RESET });
        }

    }, [dispatch, navigate, productId, product, error, isUpdated, updateError]);

    const updateProductSubmitHandler = (e) => {
        e.preventDefault();

        const myForm = new FormData();

        myForm.set("name", name);
        myForm.set("price", price);
        myForm.set("description", description);
        myForm.set("category", category);
        myForm.set("Stock", Stock);
        myForm.set("brand", brand);
        myForm.set("dimensions", dimensions);
        myForm.set("lockType", lockType);
        myForm.set("color", color);
        myForm.set("volume", volume);
        myForm.set("weight", weight);
        myForm.set("material", material);
        myForm.set("warranty", warranty);
console.log("test",images)
        myForm.set("images", JSON.stringify(images));

        // images.forEach((image) => {
        //     myForm.append("images", image);
        // });
        dispatch(updateProduct(productId, myForm));




    };

    const gotToNewPage = () => {
        navigate("/admin/products")
    }

    const updateProductImagesChange = (e) => {
        const files = Array.from(e.target.files);
        var setImg = [];
        var setImgPreview = [];
        setImages([]);
        setImagesPreview([]);

        files.forEach((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {

                    setImg.push(reader.result)
                    setImgPreview.push(reader.result)
                }
                // console.log(setImg,files)
                if (setImg.length === files.length) {
                    setImagesPreview(setImg);
                    setImages(setImgPreview);

                }
            };
            reader.readAsDataURL(file);
        });
    }


    return (
        <Fragment>
            <MetaData title="Create - Product" />
            <div className='dashboard'>
                <SideBar />
                <div className='newProductContainer'>
                    <form
                        className='createProductForm'
                        encType='multipart/form-data'
                        onSubmit={updateProductSubmitHandler}
                    >
                        <h1>Update Product</h1>
                        <div>
                            <Spellcheck />
                            <input
                                type='text'
                                placeholder='Product Name'
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div>
                            <AttachMoney />
                            <input
                                type='number'
                                placeholder='Price'
                                required
                                onChange={(e) => setPrice(e.target.value)}
                                value={price}
                            />
                        </div>
                        <div>
                            <Description />
                            <textarea
                                placeholder='Product Descripation'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                cols="30"
                                rows="1"
                            ></textarea>
                        </div>
                        <div>
                            <AccountTree />
                            <select value={category} onChange={(e) => setCategory(e.target.value)}>
                                <option value="">Choose Category</option>
                                {categories.map((cate) => (
                                    <option key={cate} value={cate}>
                                        {cate}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <Storage />
                            <input
                                type="number"
                                placeholder='Stock'
                                required
                                onChange={(e) => setStock(e.target.value)}
                                value={Stock}
                            />
                        </div>
                        <div>
                            <Dns />
                            <input
                                type='text'
                                placeholder='Brand Name'
                                required
                                value={brand}
                                onChange={(e) => setBrand(e.target.value)}
                            />
                        </div>
                        <div>
                            <AspectRatio />
                            <input
                                type='text'
                                placeholder='Dimensions'
                                required
                                value={dimensions}
                                onChange={(e) => setDimensions(e.target.value)}
                            />
                        </div>
                        <div>
                            <Lock />
                            <input
                                type='text'
                                placeholder='LockType'
                                required
                                value={lockType}
                                onChange={(e) => setLockType(e.target.value)}
                            />
                        </div>
                        <div>
                            <ColorLens />
                            <input
                                type='text'
                                placeholder='Color'
                                required
                                value={color}
                                onChange={(e) => setColor(e.target.value)}
                            />
                        </div>
                        <div>
                            <Equalizer />
                            <input
                                type='text'
                                placeholder='Volume'
                                required
                                value={volume}
                                onChange={(e) => setVolume(e.target.value)}
                            />
                        </div>
                        <div>
                            <LineWeight />
                            <input
                                type='text'
                                placeholder='Weight'
                                required
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                            />
                        </div>
                        <div>
                            <Apps />
                            <input
                                type='text'
                                placeholder='Material'
                                required
                                value={material}
                                onChange={(e) => setMaterial(e.target.value)}
                            />
                        </div>
                        <div>
                            <Verified />
                            <input
                                type='text'
                                placeholder='Warranty'
                                required
                                value={warranty}
                                onChange={(e) => setWarranty(e.target.value)}
                            />
                        </div>
                        <div id='createProductFormFile'>
                            <input
                                type='file'
                                name="avatar"
                                accept='image/*'
                                onChange={updateProductImagesChange}
                                multiple
                            />
                        </div>
                        {/* <div id='createProductFormImage'>
                            {oldImages && oldImages.map((image, index) => (
                                <img key={index} src={image.url} alt="Old Product preview" />
                            ))}
                        </div> */}
                        <div id='createProductFormImage'>


                            {imagesPreview?.map((image, index) => (
                                <img key={index} src={(image?.url || image)} alt="Product preview" />
                            ))}
                        </div>
                        <div>
                            <Button
                                id="cancleProductBtn"
                                type="submit"
                                onClick={() => gotToNewPage()}
                                disabled={loading ? true : false}
                            >
                                cancle
                            </Button>
                            <Button
                                id="saveProductBtn"
                                type="submit"
                                disabled={loading ? true : false}
                            >
                                save
                            </Button>
                        </div>

                    </form>
                </div>
            </div>

            <ToastContainer position="top-center" />
        </Fragment>
    )
}

export default UpdateProduct