import React, { Fragment, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import "./ProductDetails.css";
import { useSelector, useDispatch } from "react-redux";
import {  getProductDetails, newReview } from '../../actions/productAction';
import { useParams } from "react-router-dom";
// import ReactStars from "react-rating-stars-component";
import ReviewCard from "./ReviewCard.js"
import Loader from "../layout/Loader/loader.js"
import MetaData from '../layout/MetaData.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addItemToCart } from "../../actions/cartAction.js";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { Fingerprint, Password, LockOpen, AssuredWorkload, Call, Verified, LocalShipping } from "@mui/icons-material"
import { Rating } from "@mui/lab";
import { NEW_REVIEW_RESET } from '../../constants/productConstants.js';




const ProductDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { product, loading, error } = useSelector(
        (state) => state.productDetails
    );
    const { success, error: reviewError } = useSelector((state) => state.newReview)
    // const { user } = useSelector(state => state.user);

    const options = {
        size: "large",
        value: product.ratings,
        readOnly: true,
        precision: 0.5,
    };


    const [quantity, setQuantity] = useState(1);
    const [open, setOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");

    const icreasequantity = () => {
        if (product.Stock <= quantity) return;
        const qty = quantity + 1;
        setQuantity(qty);
    };

    const decreasequantity = () => {
        if (1 >= quantity) return;
        const qty = quantity - 1;
        setQuantity(qty);
    };

    const addToCartHandler = () => {
        dispatch(addItemToCart(id, quantity));
        toast.success("Item Added To Cart");
    };

    const submitReviewToggle = () => {
        open ? setOpen(false) : setOpen(true);
    };

    const reviewSubmitHandler = () => {
        const myForm = new FormData();

        myForm.set("rating", rating);
        myForm.set("comment", comment);
        myForm.set("productId", product._id);

        dispatch(newReview(myForm));

        setOpen(false);
    };

    useEffect(() => {
        if (error) {
            toast.error(error);
            // dispatch(clearErrors());
        }

        if (reviewError) {
            toast.error(reviewError);
            // dispatch(clearErrors());
        }

        if (success) {
            toast.success("Review Submitted Successfully");
            dispatch({ type: NEW_REVIEW_RESET });
        }

        dispatch(getProductDetails(id));
    }, [dispatch, id, error, reviewError, success]);


    return (
        <Fragment>
            {loading ? <Loader /> : (
                <Fragment>
                    <MetaData title={`${product.name} -- ECOMMERCE`} />

                    <div className="ProductDetails">
                        <div>
                            <Carousel className='imgproduct'>
                                {product.images && product.images.map((item, i) => (
                                    <img className="CarouselImage"
                                        key={i}
                                        src={item.url}
                                        alt={`${i} Slide`} />
                                ))}
                            </Carousel>
                        </div>
                        <div>
                            <div className="detailsBlock-1">
                                <h2>{product.name}</h2>
                                {/* <p>Product # {product._id}</p> */}
                            </div>
                            <div className="detailsBlock-2">
                                <Rating {...options} />
                                <span className="detailsBlock-2-span">

                                    ({product.numofReviews} Reviews)
                                </span>
                            </div>
                            <div className="detailsBlock-3">
                                <h1>{`₹${product.price}`}</h1>
                                <div className="detailsBlock-3-1">
                                    <div className="detailsBlock-3-1-1">
                                        <button onClick={decreasequantity} >-</button>
                                        <input readOnly type="number" value={quantity} />
                                        <button onClick={icreasequantity} >+</button>
                                    </div>
                                    <button disabled={product.Stock < 1 ? true : false} onClick={addToCartHandler} >Add to Cart</button>
                                </div>


                                <p>
                                    Status :
                                    <b className={product.Stock < 1 ? "redColor" : "greenColor"}>
                                        {product.Stock < 1 ? "OutOfStock" : "InStock"}
                                    </b>
                                </p>
                            </div>

                            <div className="detailsBlock-4">
                                Description : <p>{product.description}</p>
                            </div>

                            <button className="submitReview" onClick={submitReviewToggle}>Submit Review</button>
                            <div className='ficharIcon'>
                                <div>
                                    <LockOpen className='LockOpen' fontSize="large" />
                                    <p>Password</p>
                                </div>
                                <div>
                                    <Password className='PasswordIcon' fontSize="large" />
                                    <p>OTP</p>
                                </div>
                                <div>
                                    <Fingerprint className='FingerprintIcon' fontSize="large" />
                                    <p>Fingerprint</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="Product-Details">
                        <div>
                            <h3 className='Details-hedding'>Product Details</h3>
                            <div >
                                <table className='table'>
                                    <tr>
                                        <td className='key'><b>Brand :</b></td>
                                        <td className='value'>{product.brand}</td>
                                    </tr>
                                    <tr>
                                        <td className='key'><b>Dimensions :</b></td>
                                        <td className='value'>{product.dimensions}</td>
                                    </tr>
                                    <tr>
                                        <td className='key'><b>Lock Type :</b></td>
                                        <td className='value'>{product.lockType}</td>
                                    </tr>
                                    <tr>
                                        <td className='key'><b>Colour :</b></td>
                                        <td className='value'>{product.color}</td>
                                    </tr>
                                    <tr>
                                        <td className='key'><b>Volume :</b></td>
                                        <td className='value'>{product.volume}</td>
                                    </tr>
                                    <tr>
                                        <td className='key'><b>Weight :</b></td>
                                        <td className='value'>{product.weight}</td>
                                    </tr>
                                    <tr>
                                        <td className='key'><b>Material :</b></td>
                                        <td className='value'>{product.material}</td>
                                    </tr>
                                    <tr>
                                        <td className='key'><b>Warranty :</b></td>
                                        <td className='value'>{product.warranty}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className='fichar-Icon'>
                            <div>
                                <AssuredWorkload />
                                <p>No Cost EMI</p>
                            </div>
                            <div>
                                <LocalShipping />
                                <p>Hassle-Free Returns</p>
                            </div>
                            <div>
                                <Call />
                                <p>Emergency call service</p>
                            </div>
                            <div>
                                <Verified />
                                <p>Free Delivery</p>
                            </div>
                        </div>

                    </div>

                    <h3 className="reviewsHeading">REVIEWS</h3>
                    <Dialog
                        aria-labelledby='simple-dialog-title'
                        open={open}
                        onClose={submitReviewToggle}
                    >
                        <DialogTitle>Submit Review</DialogTitle>
                        <DialogContent className='submitDialog'>
                            <Rating
                                onChange={(e) => setRating(e.target.value)}
                                value={rating}
                                size='large'
                            />
                            <textarea className='submitDialogTextArea'
                                cols="30"
                                rows="5"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            ></textarea>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={submitReviewToggle} color='secondary'>Cancel</Button>
                            <Button onClick={reviewSubmitHandler} color='primary'>Submit</Button>
                        </DialogActions>
                    </Dialog>

                    {product.reviews && product.reviews[0] ? (
                        <div className='reviews'>
                            {product.reviews && product.reviews.map((review) => <ReviewCard review={review} />)}
                        </div>
                    ) : (
                        <p className="noReviews"> No Reviews Yet</p>
                    )}

                    <ToastContainer position="bottom-center"  />
                </Fragment>
            )}
        </Fragment>
    )
};

export default ProductDetails;