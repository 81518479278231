import React, { Fragment, useState, useEffect } from 'react';
import "./ForgotPassword.css";
import Loader from '../layout/Loader/loader';
import { MailOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors , forgotPassword } from "../../actions/userAction";
import MetaData from '../layout/MetaData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {

    const dispatch = useDispatch();

    const { error, message, loading } = useSelector((state) => state.forgotPassword);

    const [email, setEmail] = useState("");

    const forgotPasswordSubmit = (e) => {
        e.preventDefault();

        const myForm = new FormData();

        myForm.set("email", email);
        dispatch(forgotPassword(myForm))
    };


    useEffect(() => {

        if (error) {
            toast.error(error);
            dispatch(clearErrors());
        }


        if (message) {
            toast.success(message);
        }
    }, [dispatch, error , message]);


  return (
    <Fragment>
            {loading ? <Loader /> : (
                <Fragment>
                    <MetaData title="Forgot Password" />
                    <div className="forgotPasswordContainer">
                        <div className="forgotPasswordBox">
                            <h2 className='forgotPasswordHeading'>Forgot Password</h2>

                            <form
                                className="forgotPasswordForm"
                                onSubmit={forgotPasswordSubmit}
                            >
                                <div className="forgotPasswordEmail">
                                    <MailOutline />
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        required
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <input
                                    type="submit"
                                    value="Send"
                                    className="forgotPasswordBtn"

                                />
                            </form>
                        </div>
                    </div>
                    <ToastContainer position="top-center" />
                </Fragment>
            )}
        </Fragment>
  )
}

export default ForgotPassword