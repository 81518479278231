import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from "./Sidebar.js";
import "./Dashboard.css";
import { Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { Doughnut , Line } from "react-chartjs-2"; 
import { Chart as ChartJS , LineElement , CategoryScale , LinearScale , PointElement , Legend , Tooltip , ArcElement} from 'chart.js';
import { getAdminProducts } from '../../actions/productAction.js';
import { getAllOrders } from '../../actions/orderAction.js';
import { getAllUsers } from '../../actions/userAction.js';


ChartJS.register( LineElement , CategoryScale , LinearScale , PointElement , Legend , Tooltip , ArcElement)

const Dashboard = () => {
  const dispatch = useDispatch();

  const { products } = useSelector((state) => state.products);
  const { orders } = useSelector((state) => state.allOrders);
  const { users } = useSelector((state) => state.allUsers);

  let outOfstock = 0;

  products && 
    products.forEach((item) => {
      if(item.Stock === 0) {
        outOfstock += 1;
      }
    });

    useEffect(() => {
      dispatch(getAdminProducts());
      dispatch(getAllOrders());
      dispatch(getAllUsers());
    } , [dispatch]);

    let totalAmount = 0;
    orders && 
      orders.forEach((item) => {
        totalAmount += item.totalPrice;
      })

  const lineState = {
    labels : ["Initial Amount", "Amount Earned"],
    datasets : [
      {
        label : "TOTAL AMOUNT",
        backgroundColor : ["tomato"],
        hoverBackgroundColor : ["rgb(197, 72, 49)"],
        data : [0, totalAmount],
        // borderColor : "green",
      },
    ],
  };

  const options = {
    plugins : {
      legend : true
    },
    scales : {
      // y : {
      //   min : 3,
      //   max : 6
      // }
    }
  }

  const doughnutState ={
    labels : ["Out of Stock" , "InStock"],
    datasets : [
      {
        backgroundColor : ["#00A6B4" , "#680084"],
        hoverBackgroundColor : ["#4B5000" , "#35014F"],
        data : [outOfstock , products.length - outOfstock],
      },
    ],
  };
  return (
    <div className='dashboard'>
      <Sidebar />
      <div className='dashboardContainer'>
        <Typography component="h1">Dashboard</Typography>
        <div className='dashboardSummary'>
          <div>
            <p>Total Amount <br /> ₹{totalAmount}</p>
          </div>
          <div className='dashboardSummaryBox2'>
            <Link to="/admin/products">
              <p>Product</p>
              <p>{products && products.length}</p>
            </Link>
            <Link to="/admin/orders">
              <p>Orders</p>
              <p>{orders && orders.length}</p>
            </Link>
            <Link to="/admin/users">
              <p>Users</p>
              <p>{users && users.length}</p>
            </Link>
          </div>
        </div>
        <div className='lineChart'>
          <Line data={lineState} options={options} />
        </div>

        <div className='doughnutChart'>
          <Doughnut data={doughnutState} />
        </div>
      </div>
    </div>
  )
}

export default Dashboard