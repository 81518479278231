import React, { Fragment, useEffect, useState } from 'react'
import "./Prodcts.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProduct } from '../../actions/productAction';
import Loader from "../layout/Loader/loader.js"
import ProductCard from '../Home/ProductCard';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";
import { Slider, Typography } from '@mui/material';
import MetaData from "../layout/MetaData.js"


const categories = [
    "Home Locker",
    "100X Stronger",
    "250X Stronger",
    "Fire Resistant Lockers",
    "Digital Lockers",
    "Mechanical Lockers",
    "Fingerprint Lockers",
]

const Products = ({ match }) => {
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1)
    const [price, setPrice] = useState([0, 500000])
    const [category, setCategory] = useState("")
    const [ratings, setRatings] = useState(0)

    const { products, loading, error, productsCount, resultPerPage } = useSelector(
        (state) => state.products
    );

    const { keyword } = useParams();

    const setCurrentPageNo = (e) => {
        setCurrentPage(e)
    }

    const priceHandler = (event, newPrice) => {
        setPrice(newPrice);
    }

    useEffect(() => {

        if (error) {
            toast.error(error);
            dispatch(clearErrors());
        }

        dispatch(getProduct(keyword, currentPage, price, category, ratings))
    }, [dispatch, keyword, currentPage, price, category, ratings, error])


    return (
        <Fragment>
            {loading ? <Loader /> : (
                <Fragment>

                    <MetaData title="PRODUCTS -- ECOMMERCE" />
                    <h2 className='productsHeading'>Produts</h2>
                    <div className='products'>
                        {products &&
                            products.map((product) => (
                                <ProductCard key={product._id} product={product} />
                            ))
                        }
                    </div>

                    <div className="filterBox">
                        <Typography>price</Typography>
                        <Slider
                            className='Slider'
                            value={price}
                            onChange={priceHandler}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={0}
                            max={500000}
                        />
                        <Typography>Categories</Typography>
                        <ul className="categoryBox">
                            {categories.map((category) => (
                                <li className="category-link"
                                    key={category}
                                    onClick={() => setCategory(category)}
                                >
                                    {category}
                                </li>
                            ))}

                        </ul>

                        <fieldset>
                            <Typography component="legend">Ratings Above</Typography>
                            <Slider
                                value={ratings}
                                onChange={(e, newRating) => {
                                    setRatings(newRating);
                                }}
                                aria-labelledby="continuous-slider"
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                            />
                        </fieldset>
                    </div>

                    {resultPerPage < productsCount && (
                        <div className="paginationBox">
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={resultPerPage}
                                totalItemsCount={productsCount}
                                onChange={setCurrentPageNo}
                                nextPageText="Next"
                                prevPageText="Prev"
                                firstPageText="1st"
                                lastPageText="Last"
                                itemClass="page-item"
                                linkClass="page-link"
                                activeClass="pageItemActive"
                                activeLinkClass="pageLinkActive"
                            />
                        </div>
                    )}
                    <ToastContainer />
                </Fragment>
            )}
        </Fragment>
    )
}

export default Products