// import React, { Fragment, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";


const ProtectedRoutes = () => {

  const { isAuthenticated } = useSelector((state) => state.user);

  if (!isAuthenticated) {

    return <Navigate to="/login" />

  }
  return <Outlet />



}

export default ProtectedRoutes;