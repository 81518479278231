import React, { useState, useEffect, Fragment } from 'react'
import "./Slider.css";
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import SliderData from "./SliderData"

const Slider = () => {
    const [people] = useState(SliderData);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const lastIndex = people.length - 1;
        if (index < 0) {
            setIndex(lastIndex);
        }
        if (index > lastIndex) {
            setIndex(0);
        }
    }, [index, people]);

    useEffect(() => {
        let slider = setInterval(() => {
            setIndex(index + 1);
        }, 5000);
        return () => {
            clearInterval(slider);
        };
    }, [index]);

    return (
        <Fragment>
            <section className='section'>
                <div className='title'>
                    <h2>Top Leader</h2>
                </div>
                <div className='section-center'>
                    {people.map((item, indexPeople) => {
                        const { id, image, name, title, quote } = item;
                        let position = "nextSlide";
                        if (indexPeople === index) {
                            position = "activeSlide";
                        }
                        if (indexPeople === index - 1 || (index === 0 && indexPeople === people.length - 2)) {
                            position = "lastSlide";
                        }
                        return (
                            <article className={position} key={id}>
                                <img src={image} alt={name} className='person-img' />
                                <h4>{name}</h4>
                                <p className='title'>{title}</p>
                                <p className='text'>{quote}</p>
                            </article>
                        )
                    })}
                    <button className='prev' onClick={() => setIndex(index - 1)} >
                        <i><ArrowLeft /></i>
                    </button>
                    <button className='next' onClick={() => setIndex(index + 1)} >
                        <i><ArrowRight /></i>
                    </button>
                </div>
            </section>
        </Fragment>
    )
}

export default Slider