import React, { Fragment } from 'react'
import "./Contact.css";
import { Email, LocationOn, PhoneIphone, WatchLater } from "@mui/icons-material";
import { Button } from '@mui/material';
// import { Link } from 'react-router-dom';
import emailphoto from "../../images/emailpng.png";

const Contact = () => {

    // const [name, setName] = useState("");
    // const [email, setEmail] = useState("");
    // const [message, setMessage] = useState("");

    return (
        <Fragment>
        
            <section className='contact-section'>
                <div className='contact-bg'>
                    <h3>Get in Touch With Us</h3>
                    <h2>contact us</h2>
                    <div className='line'>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <p className='text'>Connect with our expert team an our dedicated helpdesk</p>
                </div>

                <div className='contact-body'>
                    <div className='contact-info'>
                        <div>
                            <span><i className='fas'><PhoneIphone /></i></span>
                            <span>Phone No.</span>
                            <span className='text'>0261-2452181</span>
                        </div>
                        <div>
                            <span><i className='fas'><Email /></i></span>
                            <span>E-mail</span>
                            <span className='text'>dm.pvtltd.info@gmail.com</span>
                        </div>
                        <div>
                            <span><i className='fas'><LocationOn /></i></span>
                            <span>Address</span>
                            <span className='text'>H.GR.Floor-01, Tulsi Arcade, Opp. Gabani Hospital, Lal Darwaja, Station Road Surat Gujarat-395003, India</span>
                        </div>
                        <div>
                            <span><i className='fas'><WatchLater /></i></span>
                            <span>Opening Hours</span>
                            <span className='text'>Monday - friday (9:00 AM to 6:00 PM)</span>
                        </div>
                    </div>
                    <div className='contact-form'>

                        <form>
                            <div>
                                <a className="mailBtn" href="mailto:bellafashion1818@gmail.com">
                                    <Button>Contact: dm.pvtltd.info@gmail.com</Button>
                                </a>
                            </div>
                            {/* <div>
                                <input type='email' id='email' className='form-control' placeholder='E-mail' name="email" onChange={e => setEmail(e.target.value)} />
                            </div>
                            <textarea rows="5" id='message' placeholder='Message' className='form-control' onChange={e => setMessage(e.target.value)}></textarea>
                            <input type='submit' className='send-btn' value="send message" /> */}
                        </form>
                        <div>
                            <img className="contact-img" src={emailphoto} />
                        </div>
                    </div>
                </div>
                <div className='map'>
                    <iframe src="https://www.google.com/maps/embed?
                        pb=!1m17!1m12!1m3!1d3719.6415972488803!2d72.
                        83616997526154!3d21.206391980487712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.
                        1!3m2!1m1!2zMjHCsDEyJzIzLjAiTiA3MsKwNTAnMTkuNSJF!5e0!3m2!1sen!2sin!4v17140
                        46036379!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>


            </section>

        </Fragment>
    )
}

export default Contact