import React from "react";
import playstore from "../../../images/playstore.png";
import appstore from "../../../images/appstore.png";
import "./Footer.css"
// import { Link } from "react-router-dom";
// import Privace_Policy from "../../Privace_Policy/Privace-Policy";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="leftFooter">
        <h4>DOWNLOAD OUR APP</h4>
        <p>Download App for Android and ios mobile phone</p>
        <img src={playstore} alt="playstore" />
        <img src={appstore} alt="appstore" />
      </div>

      <div className="midFooter">
        <h1>DM Securie</h1>
        <p>High Quality is our first priority</p>
        <p>Copyrights 2024 &copy; MD Securie </p>
      </div>

      <div className="rightFooter">
        <a href="/PrivacePolicy">Privace_Policy</a>
        <a href="/TermsAndConditions">TermsAndConditions</a>
        
      </div>
      <div className="rightFooter">
        <h4>Follow Us</h4>
        <a href="http://instagram.com">Instagram</a>
        <a href="http://youtube.com">Youtube</a>
        <a href="http://facebook.com">Facebook</a>
      </div>
    </footer>
  );
};

export default Footer;
