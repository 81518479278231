import React, { Fragment, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import "./ProductList.css";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import MetaData from "../layout/MetaData";
import { Edit, Delete } from "@mui/icons-material";
import SideBar from "./Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteOrder, getAllOrders, clearErrors } from '../../actions/orderAction';
import { useNavigate } from "react-router-dom";
import { DELETE_ORDER_RESET } from '../../constants/orderConstants';


const OrderList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { error, orders } = useSelector((state) => state.allOrders);

    // const { user } = useSelector(state => state.user);


    const { deleteError, isDeleted } = useSelector((state) => state.order);

    const getValues = (orders, key) => {
        return orders[key];
    }

    const deleteOrderHandler = (id) => {
        dispatch(deleteOrder(id));
    };

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearErrors());
        }

        if (deleteError) {
            toast.error(deleteError);
            dispatch(clearErrors());
        }

        if (isDeleted) {
            toast.success("Order Deleted Success");
            navigate("/admin/orders");
            dispatch({ type: DELETE_ORDER_RESET });
        }

        dispatch(getAllOrders());
    }, [dispatch, error, navigate, deleteError, isDeleted]);

    const columns = [
        {
            field: "id",
            headerName: "Order ID",
            minWidth: 100,
            flex: 0.8,
        },
        // {
        //     field: "email",
        //     headerName: "Email ID",
        //     minWidth: 100,
        //     flex: 0.8,
        // },
        {
            field: "createdAt",
            headerName: "Order Date",
            minWidth: 150,
            flex: 0.5,
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 150,
            flex: 0.3,
            cellClassName: (id) => {
                return getValues(id, "status") === "Delivered" ? "greenColor" : "redColor";
            },
        },
        {
            field: "itemsQty",
            headerName: "Items Qty",
            type: "number",
            minWidth: 100,
            flex: 0.4,
        },
        {
            field: "amount",
            headerName: "Amount",
            type: "number",
            minWidth: 200,
            flex: 0.3,
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "number",
            minWidth: 150,
            flex: 0.3,
            sortable: false,
            renderCell: (id) => {
                return (
                    <Fragment>
                        <Link to={`/admin/order/${getValues(id, "id")}`}>
                            <Edit />
                        </Link>
                        <Button onClick={() => deleteOrderHandler(getValues(id, "id"))}>
                            <Delete />
                        </Button>
                    </Fragment>
                );
            },
        },
    ];

    const rows = [];

    orders &&
        orders.forEach((item) => {
            rows.push({
                id: item._id,
                // email: user.email,
                itemsQty: item.orderItems.length,
                amount: item.totalPrice,
                status: item.orderStatus,
                createdAt: item.createdAt,
            });
        });

    return (
        <Fragment>
            <MetaData title={`All ORDERS - ADMIN`} />
            <div className='dashboard'>
                <SideBar />
                <div className='productListContainer'>
                    <h1 id="productListHeading">ALL ORDERS</h1>

                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableRowSelectionOnClick
                        className='productListTable'
                        autoHeight
                    />
                </div>
            </div>
            <ToastContainer />
        </Fragment>
    )
}

export default OrderList;