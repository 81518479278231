import React, { useState } from "react";
// import { ReactNavbar } from "overlay-navbar";
import logo from "../../../images/dm_logo.png";
// import headerimg from "../../../images/headerimg.png";
import "./Navbar.css";
import { Search, ShoppingCart, Person } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

// import { FaUserAlt , FaSearch , FaCartPlus } from "react-icons/fa"


// const options = {
//   burgerColorHover : "#5399DC",
//   logo,
//   logoWidth : "5vmax",
//   navColor1 : "white",
//   logoHoverSize : "3px",
//   logoHoverColor : "#1C3269",
//   link1Text : "Home",
//   link2Text : "Products",
//   link3Text : "Contact",
//   link4Text : "About",
//   link1Url : "/",
//   link2Url : "/products",
//   link3Url : "/contact",
//   link4Url : "/about",
//   link1Size : "1.2vmax",
//   link1Color : "rgba(0 , 0 , 0 , 1)",
//   link1ColorHover : "#5399DC",
//   nav1justifyContent : "flex-end",
//   nav2justifyContent : "flex-end",
//   nav3justifyContent : "flex-start",
//   nav4justifyContent : "flex-start",
//   link1Margin : "1vmax",
//   profileIconUrl : "/login",
//   profileIcon : true,
//   ProfileIconElement : FaUserAlt,
//   searchIcon : true,
//   SearchIconElement : FaSearch,
//   cartIcon : true,
//   CartIconElement : FaCartPlus,
//   profileIconColor : "rgba(35 , 35 , 35 , 0.8) ",
//   profileIconColorHover : "#5399DC",
//   searchIconColor : "rgba(35 , 35 , 35 , 0.8) ",
//   searchIconColorHover : "#5399DC",
//   cartIconColor : "rgba(35 , 35 , 35 , 0.8) ",
//   cartIconColorHover : "#5399DC",
//   searchIconSize : "1.5vmax",
//   cartIconMargin : "1vmax",
// }

const Header = () => {

  const [menuOpen, setMenuOpen] = useState(false)
  return (
    //  <ReactNavbar {...options}/>
    <div className="header">
      <nav>
        <img src={logo} href="/" />
        <div className="menu" onClick={() => {
          setMenuOpen(!menuOpen);
        }}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={menuOpen ? "open" : ""}>
          <li><NavLink to="/">Home</NavLink></li>
          <li><NavLink to="/products">Product</NavLink></li>
          <li><NavLink to="/contact">Contact</NavLink></li>
          <li><NavLink to="/about">About</NavLink></li>
          <li><NavLink to="/search"><Search /></NavLink></li>
          <li><NavLink to="/Cart"><ShoppingCart /></NavLink></li>
          <li><NavLink to="/login"><Person /></NavLink></li>
        </ul>
      </nav>
      {/* <div className="text-box">
        <h1>Great Discount On Latest Collections</h1>
        <p>"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, <br/>consectetur, adipisci velit..."</p>
        <a href="#">Shop Now</a>
      </div>
      <div className="user-box">
        <img src={headerimg}/>
      </div> */}

    </div>
  );
};

export default Header;
