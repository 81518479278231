import React, { Fragment, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import "./ProductList.css";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import MetaData from "../layout/MetaData";
import { Edit, Delete } from "@mui/icons-material";
import SideBar from "./Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { getAllUsers, clearErrors, deleteUser } from '../../actions/userAction';
import { DELETE_USER_RESET } from '../../constants/userConstants';


const UsersLists = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { error, users } = useSelector((state) => state.allUsers);

    const { error: deleteError, isDeleted, message } = useSelector((state) => state.profile);

    const getValues = (users, key) => {
        return users[key];
    }

    const deleteUserHandler = (id) => {
        dispatch(deleteUser(id));
    };

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearErrors());
        }

        if (deleteError) {
            toast.error(deleteError);
            dispatch(clearErrors());
        }

        if (isDeleted) {
            // message = message ? message : 'User deleted successfully.';
            toast.success(message);
            navigate("/admin/users");
            dispatch({ type: DELETE_USER_RESET });
        }

        dispatch(getAllUsers());
    }, [dispatch, error, navigate, deleteError, isDeleted, message]);

    const columns = [
        {
            field: "id",
            headerName: "User ID",
            minWidth: 300,
            flrx: 0.8
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 350,
            flex: 0.1,
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 150,
            flex: 0.5,
        },
        {
            field: "role",
            headerName: "Role",
            minWidth: 200,
            flex: 0.5,
            cellClassName: (id) => {
                return getValues(id, "role") === "admin" ? "greenColor" : "redcolor";
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "number",
            minWidth: 150,
            flex: 0.3,
            sortable: false,
            renderCell: (id) => {
                // const id = params.get('id');
                return (
                    <Fragment>
                        <Link to={`/admin/user/${getValues(id, "id")}`}>
                            <Edit />
                        </Link>
                        <Button onClick={() => deleteUserHandler(getValues(id, "id"))}>
                            <Delete />
                        </Button>
                    </Fragment>
                );
            },
        },
    ];

    const rows = [];

    users &&
        users.forEach((item) => {
            rows.push({
                id: item._id,
                role: item.role,
                email: item.email,
                name: item.name,
            });
        });

    return (
        <Fragment>
            <MetaData title={`All USERS - ADMIN`} />
            <div className='dashboard'>
                <SideBar />
                <div className='productListContainer'>
                    <h1 id="productListHeading">ALL USERS</h1>

                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableRowSelectionOnClick
                        className='productListTable'
                        autoHeight
                    />
                </div>
            </div>
            <ToastContainer position="bottom-center" />
        </Fragment>
    )
}


export default UsersLists