import React, { Fragment, useState, useEffect } from 'react';
import "./ResetPassword.css";
import Loader from '../layout/Loader/loader';
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, resetPassword } from "../../actions/userAction";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import MetaData from '../layout/MetaData';
import { LockOpen , Lock } from "@mui/icons-material"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
    const { token } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();

   
    const { error, success , loading } = useSelector((state) => state.forgotPassword);

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const resetPasswordSubmit = (e) => {
        e.preventDefault();

        const myForm = new FormData();

        myForm.set("password", password);
        myForm.set("confirmPassword", confirmPassword);
        dispatch(resetPassword( token , myForm))
    };

    useEffect(() => {

        if (error) {
            toast.error(error);
            dispatch(clearErrors());
        }


        if (success) {
            toast.success("Password Updated Successfully");
            navigate("/login");
        }
    }, [dispatch, error , navigate, success])

    return (
        <Fragment>
            {loading ? <Loader /> : (
                <Fragment>
                    <MetaData title="Change Password" />
                    <div className="resetPasswordContainer">
                        <div className="resetPasswordBox">
                            <h2 className='resetPasswordHeading'>Update Profile</h2>

                            <form
                                className="resetPasswordForm"
                                onSubmit={resetPasswordSubmit}
                            >

                                <div>
                                    <LockOpen />
                                    <input
                                        type="password"
                                        placeholder="New Password"
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <Lock />
                                    <input
                                        type="password"
                                        placeholder="Confirm Password"
                                        required
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>


                                <input
                                    type="submit"
                                    value="Update"
                                    className="resetPasswordBtn"

                                />
                            </form>
                        </div>
                    </div>
                    <ToastContainer position="top-center" />
                </Fragment>
            )}
        </Fragment>
    )
}

export default ResetPassword