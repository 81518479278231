import React, { Fragment } from 'react'
import "./Privace_Policy.css"
import { Link } from 'react-router-dom'

const Privace_Policy = () => {
    return (
        <Fragment>
            <section className='contact-section'>
                <div className='Privace'>
                    <h1>Privace Policy</h1>
                    <h4><b>Verified user</b></h4>
                    <p>Brandbuddiez Technologies Pvt. Ltd. (hereafter “BBTPL”) is committed to protecting the privacy of its visitors (hereafter the "Visitors," "you" or “your”) to our Internet world wide website <Link>dmsecuria.com</Link> (hereafter referred to as the “Website”) that links directly to this privacy policy about the protection of personal information online. This website is owned and operated by BBTPL and We aim for the Website to be a safe and enjoyable environment for you.</p>
                    <h4><b>This privacy policy (hereinafter the "Policy") primarily describes:</b></h4>
                    <p><b>1.</b>The information that BBTPL collects through the Website, which is hosted and operated from India</p>
                    <p><b>2.</b>The use and role of cookies and similar technologies on the Website; and</p>
                    <p><b>3.</b>The use and role of cookies and similar technologies on the Website; and</p>
                    <p>This Policy does not govern BBTPL’s collection of information through any website, or by any other means, other than through this Website, or the collection of information by any parent, or subsidiaries of BBTPL ("Other Entities")</p>
                    <p>Please read this document carefully and ensure that you understand this document before accepting it or using the website .. By using the Website, you agree to the terms and conditions of this Policy and the Terms and Conditions, available on <Link>dmsecuria.com</Link>. If you do not agree to the terms and conditions of this Policy and the Terms and Conditions, please do not use the Website.</p>
                    <b>1.</b><p>Please note that our website and other digital platforms may contain links to third party websites / digital platforms which are provided for your convenience. We are only responsible for the privacy practices and security of our own digital platforms. We recommend that you check the privacy and security policies and procedures of each and every other website / digital platform that you visit. BBTPL shall not be liable for any collection, storage, use / misuse, sharing, disclosure or transfer of data, viruses or other malware, or any other harms caused to You or anyone else because of Your visit to any third-party website or any digital platforms.</p>
                    <p><b>I. Information Gathered by BBTPL through the Website.</b>
                        <p>BBTPL gathers two basic types of information through the Website: (i) "Personal Information," information from which an individual can be revealed , and (ii) "Aggregate Information," from which an individual’s identity is not revealed.
                            <p><b>a. Personal Information</b>
                                <p>You are not ordinarily required to register or provide Personal Information in order to access the Website, though certain functionalities such as a membership program, through which purchasers of products may receive discounts on online purchases, participating in any contests, etc., may require registration. BBTPL collects Personal Information from you only when you voluntarily provide it to BBTPL - for example, in contacting BBTPL through the Website, answering surveys and polls, entering contests, sweepstakes, raffles and similar promotions, signing up for email updates and any other announcements related to products and special promotions, and purchase of merchandise. Personal Information will include your contact information (such as your name, postal address, telephone numbers and/or email address), as well as your payment information, such as credit card number and expiration date ("Payment Information"), which will be collected if you wish to purchase any product or merchandise made available through the Website ("Transaction").</p>
                                <b>b. Aggregate Information</b>
                                <p>When you visit the Website, shop.godrejsecure.com/, BBTPL and the third parties with whom BBTPL shall contract to provide payment gateway services may collect anonymous information from three sources: server log files, cookies, and pixel tags.</p>
                                <b>1. Server Log Files</b>
                                <p>Your Internet Protocol (IP) address is an identifying number that is automatically assigned to your computer by your Internet Service Provider (ISP). This number shall be identified and be logged automatically in BBTPL’s server log files , at the time of you visiting the Website, along with the time(s) of your visit(s) and the page(s) that you have visited. IP addresses of all the Visitors will be used to calculate the Website usage levels, to help diagnose problems with the Website's servers and to administer the Website. BBTPL may also use IP addresses to communicate or to block access of Visitors who fail to comply with the Policy and/or the Terms and Conditions of Website usage . Collecting IP addresses is a standard practice followed on the Internet and has been carried out automatically by many websites.</p>
                                <b>2. Cookies</b>
                                <p>Cookies are data that a web server transfers to an individual's computer for record-keeping purposes. Cookies are an industry standard practice used by most of the websites across the globe to help and facilitate visitor’s ongoing access and use of any website. Cookies do not cause damage to any computer system or files, and only the website that transfers a particular cookie to the computer system can read, modify or delete such cookie. If you do not want your information to be collected through the use of cookies, there is a simple procedure to be followed majority of browsers, provide an option to user to delete such existing cookies automatically decline cookies, or be given a choice for declining or accepting the transfer of particular cookies to any user’s computer system. You should note, however, that declining cookies may make it difficult or impossible for you to access certain portions of the Website.</p>
                                <b>3. Pixel Tags</b>
                                <p>The Website may use so-called "pixel tags," "web beacons," "clear GIFs" or similar means (collectively, "Pixel Tags") to compile aggregated statistics about Website usage and response rates. Pixel Tags enables BBTPL to maintain a count of the users who have visited certain pages of the Website. When used in HTML-formatted email messages, Pixel Tags can tell the sender whether and as to when the email was opened and read by the receiver of the e-mail.</p>
                                <p><b>1.</b>Collection of Data by BBTPL may fall under the following two categories:</p>
                                <p><b>2.</b>Personal data means data about or relating to a natural person who is directly or indirectly identifiable, having regard to any characteristic, trait, attribute or any other feature of the identity of such natural person, or any combination of such features, or any combination of such features with any other information.</p>
                                <p><b>3.</b>Sensitive personal data or information of a person means such personal information which consists of information relating to: (i) password; (ii) financial information such as bank account or credit card or debit card or other payment instrument details; (iii) physical, physiological and mental health condition; (iv) sexual orientation; (v) medical records and history; (vi) biometric information; (vii) any detail relating to the above clauses as provided to body corporate for providing service; and (viii )any of the information received under above clauses by body corporate for processing, stored or processed under lawful contract or otherwise.</p>
                                <p><b>What roles do we play in processing your data?</b>
                                    <p>We process your personal data only when there is a legal basis for doing so or when your consent has been given in this regard.</p>
                                </p>
                            </p>
                        </p>
                    </p>
                    <p><b>II. Use and Disclosure of Information Gathered by BBTPL through the Website.</b>
                        <p>. Personal Information. BBTPL may use Visitor’s Personal Information in any of the following ways:</p>
                        <p><b>1.Communications</b>
                            <p>BBTPL encourages the Visitors to contact BBTPL with their questions, queries and comments. Personal Information may be used by BBTPL to respond to the respective Visitor’s questions, queries and comments.
                            </p>
                        </p>
                        <p><b>2.Business Purposes</b>
                            <p>BBTPL may also use the Personal Information of its Visitors for internal business purposes, such as analyzing and managing the website. The Personal Information collected from the Website may be combined with other information collected by BBTPL via other online or offline sources, with demographic information and other information that is available in the public domain.</p>
                        </p>
                        <p><b>3.Transactions</b>
                            <p>When you take part in a transaction through the Website such as purchasing products, BBTPL may collect your Payment Information related to such transaction as well as other Personal Information such as shipping address for shipping of the ordered products or merchandise purchased through the Website. BBTPL may use your Personal Information or Payment Information to complete the Transaction and, if applicable, to fulfil your purchase. The Payment Information may also be shared with the third parties, as may be necessary, to complete your Transaction (for example, to process the payment made through the credit card).</p>
                        </p>
                        <p><b>4.Contests, Competitions and Other Promotions</b>
                            <p>From time to time, contests, competitions and any such similar promotions may be organized by BBTPL through its Website that may require online registration by the Visitor for participating in such contest, competition or promotion (which may include name, email, user ID and password). BBTPL may ask your certain Personal Information during the process of such registration for participating in such contest, competition or promotional schemes. BBTPL may share this Personal Information with third party sponsors of such contests, competitions and promotions (irrespective of whether it is hosted by BBTPL), in accordance with the rules applicable to such contest, competition or promotion. You are required to carefully review the rules of each contest, competition and promotion in which you participate through the Website, as they may contain certain additional important information about BBTPL or a sponsor's use of your Personal Information. To the extent that the terms and conditions of such rules concerning the treatment of your Personal Information enlarge the scope of use of such information in terms of this Policy, the terms and conditions of such rules shall also apply.</p>
                        </p>
                        <p><b>5.Promotional Communication</b>
                            <p>BBTPL may occasionally send you emails or SMS and other communication and offer you any promotions and coupons. If at any time you wish to stop receiving these communications from BBTPL, you should raise a request for discontinuation from receiving such communications by emailing, calling or writing to BBTPL using the contact information provided on the Website or by using the unsubscribe feature in the email received by you. However, you will l not be able to opt out from receiving from receiving Critical Emails from BBTPL.</p>
                        </p>
                        <p><b>6.Critical Communications</b>
                            <p>BBTPL may from time to time or as may be necessary, may or may not use your Personal Information to send emails consisting of important information regarding the Website, your Transactions, or for notifying any amendments or modifications to the terms, conditions, and policies of with respect to use of this Website ("Critical Emails")</p>
                        </p>
                        <p><b>7.Third-Party Service Providers</b>
                            <p>BBTPL works with third parties who provide services, including website hosting, credit card processing, data analysis, newsletter services, promotional activities, and other administrative services. BBTPL may share your Personal Information, including your Payment Information, with such third parties, as may be necessary, for the purpose of enabling such third parties to discharge the provision of such services. Your Personal Information may also be shared with the manufacturer of the products ordered/purchased by you on the Website or with entities responsible for delivering the products ordered/purchased by you on the Website.</p>
                        </p>
                        <p><b>8.Miscellaneous</b>
                            <p>BBTPL may disclose your Personal Information if it is required: (i) by law; (ii) to comply with legal process or governmental requests; (iii) to enforce the Terms and Conditions of Use; (iv) to protect our operations; (v) to protect the rights, privacy, safety or property of BBTPL, you or others; and (vi) to enable itself to pursue the available remedies or limit the damages that it may have sustain.</p>
                        </p>
                        <p><b>9.Aggregate Information</b>
                            <p>BBTPL may analyze Aggregate Information to enhance the Website security, track the popularity of certain pages of the Website, track success of our email notifications, assess traffic levels on the Website and other usage data, all of which would help BBTPL to provide content tailored to the interests of the consumers, improve the Website and related services and to otherwise enhance Visitor’s experience on the Website. BBTPL may also share the Aggregate Information with its Other entities and any other third parties for completion of your transactions on the Website.</p>
                        </p>
                    </p>
                    <b>2.Other Important Notes Regarding BBTPL’s Privacy Practices</b>
                    <p><b>1. Special Note for Parents and Children</b>
                        <p>Website and the contents therein are intended for a general audience and not intended for utilization directly or independently by children under 18 years of age. If you are a child under the age of 18 years and desire to use this Website or purchase any products or merchandise (if sold through this Website), please request your parents to complete the necessary registration formalities and complete the transaction. Any use of this Website or any of its features by a child under the age of 18 years must be under parental supervision. BBTPL does not directly contact children under the age of 18 years for promotion of its special offers or for marketing purposes. BBTPL also does not knowingly collect Personal Information from any child under the age of 18 years.</p>
                    </p>
                    <p><b>2. Security</b>
                        <p>BBTPL has adequate security measures and tools in place to help protect against the loss, misuse, and alteration of the information under BBTPL’s control. Whenever any Visitor furnishes his/her credit card number, that credit card number is transmitted by the Visitor’s browser in an encrypted format using industry-standard, SSL (secure socket layer) encryption method. Even though, BBTPL endeavours to protect its Visitor’s Personal Information, BBTPL cannot guarantee the security of any information transmitted to BBTPL, through or in connection with the website. If you have any reason to believe that your interaction with BBTPL is not secured (for example, if you feel that the security of any account you might have with BBTPL has been compromised), you must immediately notify BBTPL of the problem by contacting us in accordance with "Contact Us," details as mentioned below (note that physical mail notification will delay the time it takes for BBTPL to address and respond to the problem).</p>
                    </p>
                    <p><b>3. Assignment</b>
                        <p>BBTPL reserves the right to transfer any and all information that it collects from the Visitors to a third party in the event of any merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of BBTPL’s assets or stock (including without limitation in connection with any bankruptcy or similar proceedings).</p>
                    </p>
                    <b>3.Updating Your Information and Contacting Us</b>
                    <p>
                        BBTPL has adequate procedures in place to keep your Personal Information accurate, complete and up to date for the purposes for which it is collected and used. You may review the information maintained by BBTPL and wherever appropriate, you may request BBTPL that such information should be corrected. BBTPL also provides an option for reviewing, correcting, updating or otherwise modifying information, you have previously provided, for which, you may send a mail at the following e-mail address: shop@godrejss.com Please clearly indicate the information that you wish to review or have changed. BBTPL will endeavour to comply with your request as soon as reasonably possible. Note that despite any removal of or change to Personal Information requested there may also be residual information that will remain within BBTPL’s databases and other records, which will not be removed or changed.
                    </p>
                    <p><b>1.How long do we keep your personal data?</b>
                        <p>We keep your personal data for no longer than reasonably necessary for the given purpose for which your data is processed. If you will provide us, or have provided us, consent for us to process your data, we will process your data for no longer than your consent is effective. Notwithstanding the above, we may retain your personal data as required by applicable Indian laws and regulations, as necessary to assist with any government and judicial investigations, to initiate or defend legal claims or for the purpose of civil, criminal or administrative proceedings. If none of the above grounds for us to keep your data apply, we will delete and dispose of your data in a secure manner according to our data protection policy</p>
                    </p>
                    <b>4.Choice</b>
                    <p>
                        From time to time, BBTPL may ask you to indicate whether you are interested in receiving emails and other information from BBTPL or not. If you elect to receive these communications, BBTPL will occasionally send you emails or other information that match your requests and offer you promotions and coupons.
                    </p>
                    <p>
                        If at any time you wish to stop receiving these email communications from BBTPL, you may raise a request for opting out from receiving such emails by emailing, calling or writing to BBTPL using the contact information provided on the Website or by using the unsubscribe feature in the email received by you. Please indicate that you wish to stop receiving email communications from BBTPL. However, you shall not have the option of discontinuing the receipt of Critical Emails from BBTPL.
                    </p>
                    <p>
                        Also, BBTPL does not disclose your Personal Information to third parties, including BBTPL’s subsidiaries and the holding companies, for third-party's direct marketing purposes if BBTPL has received and processed a request from you that your Personal Information should not be shared with third parties for such purposes. If you would like to submit such a request, you may do so by emailing, calling or writing to BBTPL using the contact information provided above. Please indicate that you request that BBTPL should not disclose your Personal Information to its subsidiaries and/or other third parties for direct marketing purposes.
                    </p>
                    <b>5.Changes to This Policy</b>
                    <p>BBTPL reserves the right to change this Policy, and any of its policies or procedures concerning the treatment of information collected through the Website, without prior notice. You can determine when this Policy was last revised by referring to the "Last Updated" legend at the top of this page. Any changes to the Policy will become effective upon posting of the revised Policy on the Website. Use of the Website following such changes constitutes your acceptance of the revised Policy, then in effect. BBTPL encourages you to bookmark this page and to periodically review it to ensure familiarity with the most current version of the Policy.</p>
                    <p>This Policy represents the sole, authorized statement of BBTPL’s practices with respect to the collection of Personal Information through the Website and the manner of use of such information by BBTPL. Any summaries of this Policy generated by third party software or otherwise (for example, in connection with the Platform for Privacy Preferences or "P3P") shall have no legal effect, shall not be binding on BBTPL, shall not be relied upon in substitute for this Policy, and neither supersede nor modify this Policy.</p>
                    <b>6.Consent Amendments Law</b>
                    <p>By using this Website, you consent to the terms of this Policy and to the use and management of Personal Information and Payment Information by BBTPL for the purposes and in the manner herein provided. Should this Policy change, BBTPL intend to take every reasonable step to ensure that these changes are brought to your attention by posting the updated Policy on the Website. Your visit and any dispute over privacy are subject to this Policy. The said Policy shall be governed by and construed in accordance with the laws of the Republic of India. Further, it is irrevocably and unconditionally agreed that the courts of Mumbai, India shall have exclusive jurisdiction to entertain any proceedings in relation to any disputes arising out of the same.</p>
                    <b>6.Consent Amendments Law</b>
                    <p>
                        By using this Website, you consent to the terms of this Policy and to the use and management of Personal Information and Payment Information by BBTPL for the purposes and in the manner herein provided. Should this Policy change, BBTPL intend to take every reasonable step to ensure that these changes are brought to your attention by posting the updated Policy on the Website. Your visit and any dispute over privacy are subject to this Policy. The said Policy shall be governed by and construed in accordance with the laws of the Republic of India. Further, it is irrevocably and unconditionally agreed that the courts of Mumbai, India shall have exclusive jurisdiction to entertain any proceedings in relation to any disputes arising out of the same.
                    </p>
                    <b>Whom should I contact?</b>
                    <p><b>1.</b>If you have any question about this Privacy Statement, or if you would like to exercise any of your rights, or if you have any complaints that you would like to discuss with us, please in the first instance send us signed and dated request, together with a copy of your identity card. Please be as accurate as possible:</p>
                    <p>by post to H.GR.Floor, Office-01, Tulsi Arcade, opp. Gadani Hospital, Lal Darwaja, Station Road Surat, Gujarat-395003</p>
                    <p>Mail us at: <Link>dm.pvtltd.info@gmail.com</Link></p>

                </div>
            </section>
        </Fragment>
    )
}

export default Privace_Policy