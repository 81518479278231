const people = [
    {
        id : 1,
        image : "./sliderpeople.jpg",
        name : "Haryy Stamper",
        title : "manager",
        quote : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nam eu justo sollicitudin, bibendum dui vitae, efficitur felis. Aenean sed vestibulum."
    },
    {
        id : 1,
        image : "./sliderpeople.jpg",
        name : "Michael Mosely",
        title : "manager",
        quote : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nam eu justo sollicitudin, bibendum dui vitae, efficitur felis. Aenean sed vestibulum."
    },
    {
        id : 1,
        image : "./sliderpeople.jpg",
        name : "james Van",
        title : "manager",
        quote : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nam eu justo sollicitudin, bibendum dui vitae, efficitur felis. Aenean sed vestibulum."
    },
];

export default people;