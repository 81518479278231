import React, { Fragment } from 'react'
import Maleworker from "../../images/male-worker-factory.jpg";
import "./About.css";
import { Facebook, Twitter, LinkedIn, Instagram } from '@mui/icons-material';

const About = () => {
    return (
        <Fragment>
            <div className='about-wrapper'>
                <div className='about-left'>
                    <div className='about-left-content'>
                        <div>
                            <div className='shadow'>
                                <div className='about-img'>
                                    <img src={Maleworker} alt='about images' />
                                </div>
                            </div>
                            <h2>John<br /> Wick</h2>
                            <h3>project manager</h3>
                        </div>
                        <ul className='icons'>
                            <li><i className='fab'><Facebook /></i></li>
                            <li><i className='fab'><Twitter /></i></li>
                            <li><i className='fab'><LinkedIn /></i></li>
                            <li><i className='fab'><Instagram /></i></li>
                        </ul>
                    </div>
                </div>
                <div className='about-right'>
                    <h1>hi<span>!</span></h1>
                    <h2>Here's who I am & what I do</h2>
                    <div className='about-btns'>
                        <a href="/contact"><button type='button' className='btn btn-white'>Connect with Us</button></a>
                    </div>
                    <div className='about-para'>
                        <p>At our manufacturing plants we have a relentless focus on productivity and environmental sustainability.
                        </p>
                        <p>To strengthen our approach, we have invested in a robust technology solution that has revolutionised the speed and accuracy of our data monitoring. It has enabled us to map, understand and analyse our complete environmental footprint, from raw materials to finished goods, across factories. Our strategy to achieve our sustainability goals includes a range of efforts from energy efficiency, water conservation and waste management at our manufacturing plants, to promoting IGBC/LEED-certified green buildings.
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default About