import "./App.css";
import React from "react";
import { useEffect, useState } from "react";
import Header from "./component/layout/Header/Header.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WedFont from "webfontloader";
import Footer from "./component/layout/Footer/Footer.js";
import Home from "./component/Home/Home.js";
import ProductDetails from "./component/Product/ProductDetails.js";
import Products from "./component/Product/Products.js";
import Search from "./component/Product/search.js";
import LoginSignUp from "./component/User/LoginSignUp.js";
import store from "./store.js"
import { loadUser } from "./actions/userAction.js";
import UserOptions from "./component/layout/Header/UserOptions.js";
import { useSelector } from "react-redux";
import Profile from "./component/User/Profile.js";
import ProtectedRoutes from "./component/Route/ProtectedRoute.js";
import UpdateProfile from "./component/User/UpdateProfile.js";
import UpdatePassword from "./component/User/UpdatePassword.js";
import ForgotPassword from "./component/User/ForgotPassword.js";
import ResetPassword from "./component/User/ResetPassword.js";
import Cart from "./component/Cart/Cart.js";
import Shipping from "./component/Cart/Shipping.js";
import ConfirmOrder from "./component/Cart/ConfirmOrder.js";
import axios from "axios";
import Payment from "./component/Cart/Payment.js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrderSuccess from "./component/Cart/OrderSuccess.js";
import MyOrders from "./component/Order/MyOrders.js";
import OrderDetails from "./component/Order/orderDetails.js";
import Dashboard from "./component/admin/Dashboard.js";
import ProductList from "./component/admin/ProductList.js"
import NewProduct from "./component/admin/NewProduct.js";
import UpdateProduct from "./component/admin/UpdateProduct.js";
import OrderList from "./component/admin/OrderList.js";
import ProcessOrder from "./component/admin/ProcessOrder.js";
import UsersList from "./component/admin/UsersList.js";
import UpdateUser from "./component/admin/UpdateUser.js";
import ProductReviews from "./component/admin/ProductReviews.js";
import Contact from "./component/Contact/Contact.js"
import About from "./component/About/About.js";
import NotFound from "./component/NotFound/NotFound.js";
import PrivacPolicy from "./component/Privace_Policy/Privace-Policy.js";
import TermsAndConditions from "./component/TermsAndConditions/TermsAndConditions.js";

function App() {

  const { isAuthenticated, user } = useSelector(state => state.user);

  const [stripeApiKey, setStripeApiKey] = useState("");


  async function getStripeApiKey() {
    const { data } = await axios.get("/api/v1/stripeapikey");

    setStripeApiKey(data.stripeApiKey);
  };


  useEffect(() => {
    WedFont.load({
      google: {
        families: ["Roboto", "Droid Sans", "chilanka"],
      },
    });


    store.dispatch(loadUser());
    getStripeApiKey();
  }, []);

  return (
    <Router>
      <Header />

      {isAuthenticated && <UserOptions user={user} />}
      <Routes>

        <Route path="/" element={<Home />}></Route>
        <Route path="/products/:id" element={<ProductDetails />}></Route>
        <Route path="/products" element={<Products />}></Route>
        <Route path="/products/:keyword" element={<Products />}></Route>
        <Route path="/search" element={<Search />}></Route>
        <Route path="/PrivacePolicy" element={<PrivacPolicy />}></Route>
        <Route path="/TermsAndConditions" element={<TermsAndConditions />}></Route>
        <Route path="/*" element={<NotFound />}></Route>

        <Route element={<ProtectedRoutes />}>
          <Route path="/account" element={<Profile />}></Route>
          <Route path="/me/update" element={<UpdateProfile />}></Route>
          <Route path="/password/update" element={<UpdatePassword />}></Route>
          <Route path="/shipping" element={<Shipping />}></Route>
          <Route path="/success" element={<OrderSuccess />}></Route>
          <Route path="/orders" element={<MyOrders />} ></Route>
          <Route path="/order/confirm" element={<ConfirmOrder />}></Route>
          <Route path="/cart" element={<Cart />}></Route>
        </Route>
        <Route path="/order/:id" element={<OrderDetails />} ></Route>

        <Route path="/password/forgot" element={<ForgotPassword />}></Route>
        <Route path="/password/reset/:token" element={<ResetPassword />}></Route>
        <Route path="/login" element={<LoginSignUp />} ></Route>
        <Route path="/contact" element={<Contact />} ></Route>
        <Route path="/about" element={<About />} ></Route>

        <Route element={<ProtectedRoutes />}>
          <Route path="/admin/dashboard" element={<Dashboard />} ></Route>
          <Route path="/admin/products" element={<ProductList />} ></Route>
          <Route path="/admin/product" element={<NewProduct />} ></Route>
          <Route path="/admin/product/:id" element={<UpdateProduct />} ></Route>
          <Route path="/admin/orders" element={<OrderList />} ></Route>
          <Route path="/admin/users" element={<UsersList />} ></Route>
          <Route path="/admin/reviews" element={<ProductReviews />} ></Route>
        </Route>
        <Route path="/admin/order/:id" element={<ProcessOrder />} ></Route>
        <Route path="/admin/user/:id" element={<UpdateUser />} ></Route>
      </Routes>

      {stripeApiKey && (
        <Elements stripe={loadStripe(stripeApiKey)}>
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route path="/process/payment" element={<Payment />}></Route>
            </Route>
          </Routes>
        </Elements>
      )}



      <Footer />
    </Router>
  );
}

export default App;
